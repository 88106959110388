<template>
	<div>
		<el-card>
			<el-row>
				<el-button type="primary" @click="addLevel">新增服务类型</el-button>
			</el-row>
			<!-- 服务类型表单 -->
			<el-table :data="typeData" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column prop="type_id" label="ID"></el-table-column>
        <el-table-column label="logo">
          <template slot-scope="scope">
            <div>
              <el-image style="width: 60px; height: 60px;" :src="scope.row.banner" fit="fit"></el-image>
            </div>
          </template>
        </el-table-column>
				<el-table-column prop="type_name" label="类型名称"></el-table-column>
        <el-table-column :show-overflow-tooltip="true" label="职业名称">
          <template slot-scope="scope">
            <el-tag type="primary" disable-transitions v-if="scope.row.career">{{scope.row.career}}</el-tag>
          </template>
        </el-table-column>
				<el-table-column prop="sort" label="排序"></el-table-column>
        <el-table-column label="是否启用">
          <template slot-scope="scope">
            <el-switch v-model="scope.row.is_open" :active-value="1" :inactive-value="0" @change="changeState(scope.row)"></el-switch>
          </template>
        </el-table-column>
				<el-table-column label="操作" width="250">
					<template slot-scope="scope">
						<el-button type="primary" plain size="small" @click="editLevel(scope.row)">编辑</el-button>
						<el-button type="danger" plain size="small" @click="removeLevel(scope.row,$event)">删除</el-button>
						<el-button type="success" plain size="small" @click="setFee(scope.row)">费用设置</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
			<!-- 新增对话框 -->
			<el-dialog :title="title" width="600px" :visible.sync="addLevelDialog">
				<el-form ref="addlevelForm" :model="addlevelForm" label-width="100px" :rules="addlevelRules">
					<el-form-item label="类型名称" prop="type_name">
						<el-input v-model="addlevelForm.type_name" maxlength="10" show-word-limit placeholder="请输入服务类型名称"></el-input>
					</el-form-item>
          <el-form-item label="职业名称" prop="career">
            <el-input v-model="addlevelForm.career" maxlength="10" show-word-limit placeholder="请输入职业名称"></el-input>
          </el-form-item>
					<el-form-item label="排序" prop="sort">
						<el-input v-model.number="addlevelForm.sort" placeholder="请输入排序序号"></el-input>
					</el-form-item>
          <el-form-item label="Logo" prop="service_logo" style="text-align: left">
            <image-upload v-model="addlevelForm.banner" :limit="1" :fileType="['png', 'jpg', 'jpeg']" :isShowTip="true" :fileSize="0.3" save-path="service"></image-upload>
          </el-form-item>
					<el-button type="default" @click="addLevelDialog=false">取消</el-button>
					<el-button type="primary" @click="editNewLevel">确定</el-button>
				</el-form>
			</el-dialog>
      <!-- 费用设置 -->
      <el-dialog title="费用设置" width="700px" :visible.sync="feeDialog">
        <el-form ref="feeForm" :model="feeForm" label-width="130px" :rules="feeRules">
          <el-row>
            <el-col :span="12">
              <el-form-item label="技师前" prop="before_month">
                <el-input v-model="feeForm.before_month" type="number" placeholder="前几个月" oninput="if(value<1)value=1; else if(value>12)value=12">
                  <template #append>月</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="3" class="flex-box">
              分佣比例
            </el-col>
            <el-col :span="7">
              <el-form-item label="" prop="before_bl" label-width="10px">
                <el-input v-model="feeForm.before_bl" type="number" placeholder="请输入" oninput="if(value<0)value=0; else if(value>100)value=100">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="最低提现额度" prop="min_txje">
                <el-input v-model="feeForm.min_txje" type="number" placeholder="请输入最低提现额度" oninput="if(value<0)value=0; else if(value>100)value=100">
                  <template #append>元</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="flex-box">
              <el-tooltip placement="right" class="tooltips">
                <div slot="content">技师/代理/经理/门店最低可提现额度</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="22">
              <el-form-item label="服务费扣费" prop="fwf_bl">
                <el-input v-model="feeForm.fwf_bl" type="number" placeholder="请输入服务费扣费百分比" max="6" oninput="if(value<0)value=0; else if(value>6)value=6">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2" class="flex-box">
              <el-tooltip placement="right" class="tooltips">
                <div slot="content">服务费扣费最大不能超过6%</div>
                <i class="el-icon-question"></i>
              </el-tooltip>
            </el-col>
            <el-col :span="22">
              <el-form-item label="车费提现手术费" prop="tixian_bl">
                <el-input v-model="feeForm.tixian_bl" type="number" placeholder="请输入提现手术费" oninput="if(value<0)value=0; else if(value>10)value=10">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="门店分成比例" prop="store_bl">
                <el-input v-model="feeForm.store_bl" type="number" placeholder="请输入门店费用比例" max="50" oninput="if(value<0)value=0; else if(value>50)value=50">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="22">
              <el-form-item label="门店技师分成比例" prop="store_jishi_bl">
                <el-input v-model="feeForm.store_jishi_bl" type="number" placeholder="请输入门店技师分成比例" max="50" oninput="if(value<0)value=0; else if(value>50)value=50">
                  <template #append>%</template>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-button type="default" @click="feeDialog=false">取消</el-button>
          <el-button type="primary" @click="doSetFee">确定</el-button>
        </el-form>
      </el-dialog>
		</el-card>
	</div>
</template>

<script>
  let that;
	let timer = null;
	import Pagination from '@/components/Pagination/Pagination.vue'
  import {updateTypeFee} from '@/api/request/service'
  import ImageUpload from '@/components/Upload/ImageUpload.vue'
	export default {
		components: {
			Pagination,ImageUpload
		},
		data() {
			return {
				title: "新增服务类型",
				queryInfo: {
					query: "",
					pagenum: 1,
					pagesize: 10,
				},
				addLevelDialog: false,
				total: 0,
				addlevelForm: {
          banner: "",
					type_name: '',
          career: '',
					sort: 99,
				},
				addlevelRules: {
					type_name: [{required: true, message: "请输入服务类型名称", trigger: "blur"}, ],
          career: [{required: true, message: "请输入职业名称", trigger: "blur"}, ],
					sort: [{
              validator: (rule, value, callback) => {
                if (!value) return callback(new Error('请输入大于0的整数'));
                if (value <= 0 || !Number.isInteger(value)) callback(new Error('请输入大于0的整数'));
                callback();
              },
              trigger: 'blur'
            }
          ]
				},
				typeData: [],
        feeDialog: false,
        feeForm: {
          type_id: '',
          before_month: '',
          before_bl: '',
          first_bl: '',
          add_bl: '',
          min_txje: '',
          qudao_bl: '',
          fwf_bl: '',
          tixian_bl: '',
          store_bl: '',
          store_jishi_bl: ''
        },
        feeRules: {
          min_txje: [{required: true, message: "请输入最小提现金额", trigger: "blur"},],
          fwf_bl: [{required: true, message: "请输入服务费扣费", trigger: "blur"},],
          // before_month: [{type: "number", message: "请输入数字", trigger: "blur"},],
          // before_bl: [{type: "number", message: "请输入数字", trigger: "blur"},],
          // qudao_bl: [{type: "number", message: "请输入数字", trigger: "blur"},],
          // tixian_bl: [{type: "number", message: "请输入数字", trigger: "blur"},],
          // store_bl: [{type: "number", message: "请输入数字", trigger: "blur"},],
          // store_jishi_bl: [{type: "number", message: "请输入数字", trigger: "blur"},],
        },
			}
      // {
      //   required: true,
      //       message: "请输入排序序号",
      //     trigger: "blur"
      // },
      // {
      //   type: "number",
      //       message: "请输入数字"
      // },
		},
		created() {
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				that = this;
				this.getServiceType();
			}
		},
		methods: {
			getServiceType() {
				var url = 'service_type/type_list';
				let params = {
					type_name: this.queryInfo.query,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.typeData = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//新增服务类型
			addLevel() {
				this.title = "新增服务类型";
				this.addlevelForm = {type_name: '',sort: 99, banner: ""};
				this.addLevelDialog = true
			},
			//确认新增
			editNewLevel() {
        // console.log(this.addlevelForm);
        // return
				this.$refs.addlevelForm.validate(valid => {
					if(!valid) {
						return;
					} else {
						if(timer != null){clearTimeout(timer);}
        				timer = setTimeout(() => {
							var url = 'service_type/addOrUpdate';
							let params = that.addlevelForm;
							that.fd_post(url, params).then((res) => {
								if(res.status) {
									that.addLevelDialog = false;
									that.$refs.addlevelForm.resetFields();
									that.$message.success('操作成功');
									that.getServiceType();
								} else {
									that.$message.error(res.msg);
								}
							}).catch((err) => {
								that.$message.error('网络错误');
							});
						},1000);
					}
				});
			},
			//打开编辑对话框
			editLevel(levelInfo) {
				this.title = "编辑服务类型";
				this.addLevelDialog = true
				for(let k in levelInfo) {
					this.addlevelForm[k] = levelInfo[k]
				}
        console.log(this.addlevelForm)
			},
			//删除
			removeLevel(item,e) {
				this.$confirm('你确认要删除吗?', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'service_type/deleteType';
					let params = {
						type_id: item.type_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('删除成功');
              //this.typeData = [];
							this.getServiceType();
              e.target.parentNode.blur()
              e.target.blur()
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					// this.$message.info('已取消删除')
          // console.log(e)
          e.target.parentNode.blur()
          e.target.blur()
				});
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1;
				this.queryInfo.pagesize = newSize;
				this.getServiceType();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage;
				this.getServiceType();
			},
      //switch发生变化
      changeState(item) {
        var url = 'service_type/updateTypeOpen';
        let params = {
          type_id: item.type_id,
        };
        this.fd_post(url, params).then((res) => {
          if(res.code === 200) {
            this.$message.success("操作成功");
          } else {
            this.$message.error(res.msg);
          }
        }).catch((err) => {
          this.$message.error('网络错误');
        });
      },
      numberChange(val, maxNum, name) {
        if(isNaN(Number(val))){
          this.$message.error("请输入数字");
          this.feeForm[name] = 0;
          return;
        }
        //转换数字类型
        this.feeForm[name] = Number(val)
        //重新渲染
        this.$nextTick(() => {
          //比较输入的值和最大值，返回小的
          let num = Math.min(Number(val), maxNum)
          //输入负值的情况下， = 0（可根据实际需求更该）
          if(num < 0) {
            this.feeForm[name] = 0
          } else {
            //反之
            this.feeForm[name] = num
          }
        })
      },
      //确认设置
      doSetFee() {
        this.$refs.feeForm.validate(async valid => {
          if (!valid) {
            return;
          } else {
            const res = await updateTypeFee(this.feeForm).catch((err) => {
              this.$message.error('网络错误');
            });
            if (res.code === 200) {
              this.feeDialog = false;
              this.$refs.feeForm.resetFields();
              this.$message.success('操作成功');
              this.getServiceType();
            } else {
              this.$message.error(res.msg);
            }
          }
        });
      },
      //打开对话框
      setFee(item) {
        this.$router.push({
          path: '/service_type/setting_record',
          query: {
            type_id: item.type_id
          }
        })
        // return
        // this.feeDialog = true
        // console.log(item.setting)
        // if(item.setting) {
        //   this.feeForm.add_bl = parseFloat(item.setting.add_bl)
        //   this.feeForm.before_bl = parseFloat(item.setting.before_bl)
        //   this.feeForm.before_month = parseFloat(item.setting.before_month)
        //   this.feeForm.first_bl = parseFloat(item.setting.first_bl)
        //   this.feeForm.fwf_bl = parseFloat(item.setting.fwf_bl)
        //   this.feeForm.min_txje = parseFloat(item.setting.min_txje)
        //   this.feeForm.qudao_bl = parseFloat(item.setting.qudao_bl)
        //   this.feeForm.store_bl = parseFloat(item.setting.store_bl)
        //   this.feeForm.store_jishi_bl = parseFloat(item.setting.store_jishi_bl)
        //   this.feeForm.tixian_bl = parseFloat(item.setting.tixian_bl)
        //   this.feeForm.type_id = item.setting.type_id
        // } else {
        //   this.feeForm = {
        //     type_id: item.type_id,
        //     before_month: '',
        //     before_bl: '',
        //     first_bl: '',
        //     add_bl: '',
        //     min_txje: '',
        //     qudao_bl: '',
        //     fwf_bl: '',
        //     tixian_bl: '',
        //     store_bl: '',
        //     store_jishi_bl: ''
        //   }
        // }
      },
		},
	}
</script>

<style lang="scss" scoped>
	@import './Service_type.scss';
</style>